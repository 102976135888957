import Info from "../Admin/Info";
import Login from "../Admin/Login";
import About from "../Pages/About/About";
import AmsManagement from "../Pages/Blogs/AmsManagement/AmsManagement";
import AutomationAndRobotics from "../Pages/Blogs/AutomationAndRobotics/AutomationAndRobotics";
import Dashboard from "../Pages/Blogs/Dashboard/Dashboard";
import DevelopmentCenter from "../Pages/Blogs/DevelopementCenter/DevelopementCenter";
import S4HanaAssessmentAndPlaning from "../Pages/Blogs/ERP_Solution/Blogs/S4HANAAssessment/S4HanaAssessmentAndPlaning";
import S4HANATransformationAndImplementation from "../Pages/Blogs/ERP_Solution/Blogs/S4HANAAssessmentAndPlanning/S4HANATransformationAndImplementation";
import SapAmsService from "../Pages/Blogs/ERP_Solution/Blogs/SapAmsService/SapAmsService";
import SfdcAndSap from "../Pages/Blogs/ERP_Solution/Blogs/SfdcAndSap/SfdcAndSap";
import UxFiori from "../Pages/Blogs/ERP_Solution/Blogs/UxFiori/UxFiori";
import ErpService from "../Pages/Blogs/ERP_Solution/ErpServices";
import SFDC from "../Pages/Blogs/SFDC/SFDC";
import Careers from "../Pages/Careers/Careers";
import Job from "../Pages/Careers/Jobs/Job";
import ContactUs from "../Pages/ContactUs/ContactUs";
import HomePage from "../Pages/Home/Home";
import AppForEvent from "../Pages/Products/AppForEvent/AppForEvent";
import ETapp from "../Pages/Products/ETapp/ETapp";
import Inscan from "../Pages/Products/Inscan/Inscan";
import Services from "../Pages/Services/Services";
export const routes = [
  { path: "/", component: HomePage },
  { path: "/about", component: About },
  { path: "/erp-solution-sap", component: ErpService },
  {
    path: "/S-4HANA-assessment-and-planning",
    component: S4HanaAssessmentAndPlaning,
  },
  {
    path: "/contact-us",
    component: ContactUs,
  },
  {
    path: "/career",
    component: Careers,
  },
  {
    path: "/services",
    component: Services,
  },
  {
    path: "/S-4HANA-transformation-and-Implementation",
    component: S4HANATransformationAndImplementation,
  },
  {
    path: "/sap-ams-services",
    component: SapAmsService,
  },
  {
    path: "/ux-and-fiori-app-for-training",
    component: UxFiori,
  },
  {
    path: "/sfdc-and-sap-integration",
    component: SfdcAndSap,
  },
  {
    path: "/automation",
    component: AutomationAndRobotics,
  },
  {
    path: "/sfdc",
    component: SFDC,
  },
  {
    path: "/ams-management",
    component: AmsManagement,
  },
  {
    path: "/development-center",
    component: DevelopmentCenter,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  // {
  //   path: "/products",
  //   component: Products,
  // },
  {
    path: "/app-for-training",
    component: ETapp,
  },
  {
    path: "/inscan",
    component: Inscan,
  },
  {
    path: "/app-for-event",
    component: AppForEvent,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/info",
    component: Info,
  },
  {
    path: "/job/:id",
    component: Job,
  },
];
